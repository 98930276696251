<template>
  <TransitionRoot :show="open" as="div">
    <Dialog as="div" class="relative" style="z-index: 51" :initial-focus="search" @close="closeSearch">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <DialogPanel
            class="w-full max-w-[568px] transform rounded-2xl bg-neutral-white p-4 text-left align-middle shadow-xl transition-all h-[484px]"
            style="z-index: 52"
          >
            <div class="flex items-center relative">
              <input
                ref="search"
                v-model="store.searchQuery"
                class="border-b border-solid border-neutral-300 pt-4 pb-2 px-2 w-full outline-none bg-neutral-white"
                placeholder="Search orders or claims..."
                @focus="() => (isNavigatingOnSearch = true)"
                @blur="() => (isNavigatingOnSearch = false)"
                @keyup="onSearch"
              />
              <button
                v-if="store.searchQuery.length"
                class="w-4 h-4 border rounded-full flex absolute top-1/2 right-[5px] transform -translate-y-1/2 items-center justify-center bg-neutral-300"
                @click="
                  () => {
                    store.searchQuery = '';
                    search?.focus();
                  }
                "
              >
                <div class="text-xs text-white font-semibold">x</div>
              </button>
            </div>

            <div
              v-if="showEmptyState"
              class="flex items-center justify-center h-full -mt-10 text-sm text-typography-disabled flex-col"
            >
              <PulseLineSearch class="fill-current text-neutral-300 h-5 w-5 mb-2" />
              Find orders and claims by order ID or claim ID. Press Escape to close.
            </div>
            <div v-else-if="searchResults.length > 0" class="mt-5 overflow-auto search-results">
              <div
                v-for="result in searchResults"
                :key="result.id"
                class="flex items-center mt-2 cursor-pointer hover:bg-neutral-100 py-2 px-3 rounded-lg gap-5 -nav-item"
                @click="handleNavigation(result)"
              >
                <div
                  class="rounded h-8 w-8 border-neutral-300 border flex items-center justify-center flex-none"
                  :class="{ 'bg-neutral-300': !currentStore?.logo }"
                >
                  <img v-if="currentStore?.logo" :src="currentStore?.logo" class="h-6 w-6" />
                </div>
                <div>
                  <div class="font-medium text-xsm">
                    {{ result.type }} #{{ result.printableId }}
                    <span v-if="isMultipleStores"> - {{ getStoreById(result) }}</span>
                  </div>
                  <div class="text-xs text-typography-disabled">
                    {{ result.customer }} • {{ result.date }} • {{ result.itemsCountHumanized }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="commandsPalette.length > 0" class="mt-5 overflow-auto command-palette-results">
              <div
                v-for="result in commandsPalette"
                :key="result.command"
                class="flex items-center mt-2 cursor-pointer hover:bg-neutral-100 py-2 px-3 rounded-lg gap-5 -nav-item"
                @click="handleNavigation(result)"
              >
                <div>
                  <div class="font-medium text-xsm">{{ result.command }}</div>
                </div>
              </div>
            </div>
            <div
              v-else-if="isLoading"
              class="flex items-center justify-center h-full -mt-10 text-sm text-typography-disabled flex-col"
            >
              Loading...
            </div>
            <div
              v-else
              class="flex items-center justify-center h-full -mt-10 text-sm text-typography-disabled flex-col"
            >
              No results found :(
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { TransitionRoot, TransitionChild, DialogPanel, Dialog } from '@headlessui/vue';
import { ClaimModel, OrderModel } from '@pulse/models';

import debounce from 'lodash/debounce';

import { useApplicationStore } from '~/store/application';
import { useMerchantStore } from '~/store/merchant';
import { useUserStore } from '~/store/user';
import { SEARCH_DEBOUNCE_TIME_MS, SEARCH_RESET_TIME_MS, SEARCH_NAVIGATIONAL_PAGES } from '~/support/constants';

const { query } = useGQL();
const store = useApplicationStore();
const userStore = useUserStore();
const merchantStore = useMerchantStore();
const search = ref<HTMLInputElement | null>(null);
const open = computed(() => {
  return store.showSearchModal;
});
const isNavigatingOnSearch = ref(false);
const fixedPagesGoTo = SEARCH_NAVIGATIONAL_PAGES(merchantStore?.currentStore?.slug as string).map((i: any) => ({
  command: `Go to ${i.title}`,
  exec: () => {
    navigateTo(i.path);
  },
}));

const listOfCommandsAvailable = [
  ...fixedPagesGoTo,
  {
    command: 'Logout',
    exec: () => {
      userStore.logout();
      navigateTo('/login');
    },
  },
];

const currentStore = computed(() => {
  return merchantStore.currentStore;
});

const searchResults = ref<any[]>([]);
const commandsPalette = computed<any[]>(() => {
  if (store.searchQuery.startsWith('/')) {
    return listOfCommandsAvailable.filter(i =>
      i.command.toLowerCase().includes(store.searchQuery.toLowerCase().slice(1)),
    );
  }

  return [];
});

const searchClaimsAndOrders = async (searchQuery: string) => {
  isLoading.value = true;
  const { data: claimResults } = await query('getClaims', {
    first: 10,
    search: searchQuery,
    storeId: currentStore?.value?.id,
  });
  const { data: orderResults } = await query('getOrders', {
    first: 10,
    search: searchQuery,
    storeId: currentStore?.value?.id,
  });
  searchResults.value = [...ClaimModel.fromArray(claimResults?.claims), ...OrderModel.fromArray(orderResults?.orders)];
  isLoading.value = false;
  setTimeout(() => {
    setupNavigationEngine();
    setCurrentNavActive();
  }, SEARCH_RESET_TIME_MS);
};

const latestSearchQuery = ref('');
const currentNavPos = ref(0);
const maxNavPos = ref(0);
const isLoading = ref(false);
const setupNavigationEngine = () => {
  currentNavPos.value = 0;
  maxNavPos.value = searchItems.value.length - 1;
};

const isCommandPalette = computed(() => store.searchQuery.startsWith('/'));
const debouncedSearch = debounce(() => {
  if (store.searchQuery === latestSearchQuery.value || isCommandPalette.value) {
    searchResults.value = [];

    return;
  }

  latestSearchQuery.value = store.searchQuery;
  setupNavigationEngine();

  if (store.searchQuery.length > 0 && !isCommandPalette.value) {
    searchClaimsAndOrders(store.searchQuery);
  }
}, SEARCH_DEBOUNCE_TIME_MS);

const onSearch = () => {
  setCurrentNavActive();
  if (isLoading.value) return;
  debouncedSearch();
};

const setCurrentNavActive = () => {
  const parent = document.querySelector(
    isCommandPalette.value ? '.command-palette-results' : '.search-results',
  ) as HTMLElement;
  if (!parent) return;
};

const setNavPos = (direction: 'up' | 'down') => {
  if (direction === 'up') {
    currentNavPos.value -= 1;
    if (currentNavPos.value < 0) currentNavPos.value = maxNavPos.value;
  } else if (direction === 'down') {
    currentNavPos.value += 1;
    if (currentNavPos.value > maxNavPos.value) currentNavPos.value = 0;
  }

  setCurrentNavActive();
};

const searchItems = computed(() => {
  return isCommandPalette.value ? commandsPalette.value : searchResults.value;
});

function closeSearch() {
  store.searchQuery = '';
  store.closeSearchModal();
}

function handleNavigation(result: any) {
  if (isCommandPalette.value) {
    result.exec();
  } else {
    navigateTo(result.url);
  }
  closeSearch();
}

const getStoreById = (item: any) => {
  if (item.store) return currentStore.value.find(store => store.id === item.store).name;

  return currentStore.value.find(store => store.id === item.storeId).name;
};

const isMultipleStores = computed(() => {
  return !!currentStore.value && Array.isArray(currentStore.value);
});

const showEmptyState = computed(() => !searchResults.value.length && !store.searchQuery.length);

onMounted(() => {
  window.addEventListener('keydown', e => {
    if (e.key === 'k' && e.metaKey) {
      store.openSearchModal();
    }
    if (store.isSearchModalOpen() && e.key === 'Escape') {
      closeSearch();
    }

    if (isNavigatingOnSearch.value === true) {
      if (e.key === 'ArrowDown') {
        setNavPos('down');
        e.preventDefault();
      }
      if (e.key === 'ArrowUp') {
        setNavPos('up');
        e.preventDefault();
      }
      if (e.key === 'Enter') {
        if (isCommandPalette.value) {
          commandsPalette.value[currentNavPos.value].exec();
        } else {
          navigateTo(searchResults.value[currentNavPos.value].url);
        }
        closeSearch();
      }
    }
  });
});
</script>

<style lang="scss">
.search-results,
.command-palette-results {
  max-height: calc(100% - 80px);
}
</style>
~/store/merchant
