import type { AxiosRequestConfig } from 'axios';
import crypto from 'crypto-js';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

import pick from 'lodash/pick';

export const useCacheStore = defineStore('cache', () => {
  const cache = reactive<Record<string, any>>({});

  function hash(config: AxiosRequestConfig | string) {
    if (typeof config === 'string') {
      return crypto.MD5(config).toString();
    }

    return crypto.MD5(JSON.stringify(pick(config, 'url', 'headers', 'params'))).toString();
  }

  function set(config: AxiosRequestConfig | string, data: any) {
    cache[hash(config) as string] = data;
  }

  function get<T>(config: AxiosRequestConfig | string): T | null {
    return cache[hash(config)] ?? null;
  }

  function remove(config: AxiosRequestConfig | string) {
    delete cache[hash(config)];
  }

  return {
    cache,
    get,
    hash,
    remove,
    set,
  };
});
